import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"
import MarkdownSection from "../components/BodyElements/MarkdownSection"

const Consultation = ({ pageContext, location, data }) => {
  const post = data.allConsultationsJson.nodes[0]
  const procedure = data.allProcedures.nodes[1]
  const { metaDescription, language } = pageContext

  const [isLoading, setIsLoading] = useState(false)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("fullName", fullName)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("from", location.href)

    setIsLoading(true)
    fetch("https://getform.io/f/bgdyjvza", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => {
        navigate("/thank-you/")
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  let bodySections

  bodySections = procedure.bodySections.map((section, i) => (
    <React.Fragment key={i}>
      <MarkdownSection
        colorBack={section.colorBack}
        textSection={section.textSection}
        buttons={section.buttons}
        sideColumnIs={2}
      />
    </React.Fragment>
  ))

  return (
    <Layout language={language} className="consultation">
      <SEO
        title={post.metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      {post.title !== "ppc-b/" && (
        <Section
          zeroTop
          className="consultation__heading-section px-40--mobile">
          <Columns sideColumnsSize={3}>
            <div className="column consultation__heading-container">
              <Image
                wrapperClassName="consultation__heading-image"
                publicId={post.headerImage}
              />
              <div className="consultation__heading-text">
                <Text as="h1" text={post.heading} />
                <Text as="h3" text={post.subheading} />
              </div>
            </div>
            <div className="consultation__heading-mobile-text">
              <Text as="h3" text={post.subheading} />
            </div>
          </Columns>
        </Section>
      )}

      {post.title === "ppc-b/" && (
        <Section className="consultation__background-img">
          <Columns
            sideColumnsSize={2}
            className="consultation__heading-columns">
            <div className="px-40--mobile consultation__chat-container">
              <Text
                text={post.subheading}
                className="consultation__chat-from"
              />
              <div className="consultation__chat-text-1">
                <Text text={post.heading} />
              </div>
              <div className="consultation__chat-text-2">
                <Image publicId={"MAXA/DEV/logo"} />
              </div>
            </div>
          </Columns>
        </Section>
      )}

      <Section className="consultation__form-section px-40--mobile">
        <Columns sideColumnsSize={2} className="consultation__form-columns">
          <Text as="h2" text={"Achieve Your Desired Size"} />
          <Text text={post.blurb} className="consultation__form-blurb" />
          <div className="consultation__form-container">
            <Text text={post.metaDescription} className="mb-3" />
            <div>
              <p>
                <a
                  href="/contact-us/"
                  className="standard-button contained mx-auto--mobile">
                  Contact Us
                </a>
              </p>
            </div>
          </div>
        </Columns>
      </Section>

      <Section className="px-40--mobile">
        <Columns sideColumnsSize={2} className="consultation__separator">
          <hr />
        </Columns>
      </Section>

      <section className="procedure section joshua-tree-content consultation__no-padding px-40--mobile">
        {bodySections}
      </section>

      <Section zeroBottom className="px-40--mobile">
        <Columns sideColumnsSize={2} className="consultation__separator">
          <hr />
        </Columns>
      </Section>

      <Section zeroTop zeroBottom className="px-40--mobile">
        <Columns sideColumnsSize={2} className="consultation__cta-columns">
          <div className="consultation__cta-container">
            <Image
              publicId={"MAXA/DEV/doctor-profile-image-doe"}
              wrapperClassName="consultation__cta-image"
            />
            <div className="consultation__cta-text-container">
              <Text
                text={post.cta[0].heading}
                className="consultation__cta-heading"
              />
              <Text
                text={post.cta[0].subheading}
                className="mt-1 consultation__cta-subheading"
              />
              <Button
                href={post.cta[0].button.href}
                buttonText={post.cta[0].button.buttonText}
              />
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export default Consultation

export const pageQuery = graphql`
  query consultationPage($title: String!) {
    allConsultationsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        language
        metaTitle
        metaDescription
        heading
        subheading
        headerImage
        blurb
        cta {
          button {
            appearance
            buttonText
            destination
            href
          }
          heading
          subheading
        }
      }
    }
    allProcedures: allProceduresJson {
      nodes {
        bodySections {
          textSection
          colorBack
        }
      }
    }
  }
`
